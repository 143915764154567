import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import FullThrottle from '../full-throttle'

const FullThrottleEs = ({ data, ...props }) => {
  return (
    <FullThrottle
      {...props}
      data={{ ...data, allFullThrottleJson: data.allFullThrottleEsJson }}
    />
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        banner: file(relativePath: { eq: "banner-subscribe.png" }) {
          childImageSharp {
            fluid(maxWidth: 1440) {
              aspectRatio
              base64
              sizes
              src
              srcSet
            }
          }
        }
        screenshots: allFile(
          filter: { name: { regex: "/^fullThrottleScreenshot*/" } }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              childImageSharp {
                id
                fixed(width: 300) {
                  ...GatsbyImageSharpFixed
                  height
                  width
                }
              }
            }
          }
        }
        FtLogo: allFile(
          filter: { name: { regex: "/^Full-Throttle-Logo*/" } }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              childImageSharp {
                id
                fluid(maxWidth: 400) {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
        sections: allFile(
          filter: { name: { regex: "/^fullthrottle*/" } }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              childImageSharp {
                id
                fluid(maxWidth: 1200, quality: 100) {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
        allFullThrottleEsJson {
          edges {
            node {
              bannerHeadline
              heroHeading
              heroSubheading
              heroButton
              bannerCtaMessage
              pricingStructure {
                price
                duration
                note
              }
              overview {
                title
                description
                icon
                iconAlt
              }
              introSection {
                title
                titleColor
                header
                headerColor
                bodyColor
                body1
                body2
                body3
                button
                storeButton
                buttonLink
                buttonLabel
                buttonColor
                buttonFocus
                triangle
                bg
                order
                textAlign
                imageAlt
                imageExists
              }
              secondarySection {
                title
                titleColor
                header
                headerColor
                bodyColor
                body1
                body2
                body3
                button
                storeButton
                buttonLink
                buttonLabel
                buttonColor
                buttonFocus
                triangle
                bg
                order
                textAlign
                imageAlt
                imageExists
              }
              thirdSection {
                title
                titleColor
                header
                headerColor
                bodyColor
                body1
                body2
                body3
                button
                storeButton
                buttonLink
                buttonLabel
                buttonColor
                buttonFocus
                triangle
                bg
                order
                textAlign
                imageAlt
                imageExists
              }
              features {
                title
                titleColor
                header
                headerColor
                body1
                body2
                body3
                bullets
                bodyColor
                textAlign
                imageAlt
                image
                order
                triangle
                bg
              }
              ftTableData {
                tableHeader
                tableFeatures {
                  title
                  features {
                    description
                    basic
                    fullThrottle
                  }
                }
              }
              imageSections {
                containerColor
                urlDescription
                flip
                reverse
                button
                buttonLink
                buttonLabel
                buttonColor
                focus
                src
                box
                srcDescription
                titleColor
                title
                firstBody
                secondBody
                thirdBody
                storeButton
              }
              video {
                introMessage
                title
                srcUrl
              }
              pageTitle
              pageDescription
              socialTitle
              socialDescription
              socialImage
              termsDisclaimer
            }
          }
        }
      }
    `}
    render={data => <FullThrottleEs data={data} {...props} />}
  />
)
